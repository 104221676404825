var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "js-dialog-form js-car-import",
      attrs: {
        title: "导入人像",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        width: "560px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", { staticStyle: { "font-size": "18px" } }, [
        _vm._v(
          "请保证照片为清晰正向人脸图片，支持png，jpg格式，单个图像大小不大于5M，导入人像前，请将照片命名与afr.xlsx中照片那一列的名字一致。请将照片文件和afr.xlsx压缩成一个zip上传。"
        ),
      ]),
      _c("ul", [
        _c("li", { staticClass: "label" }, [_vm._v("第一步")]),
        _c(
          "li",
          [
            _c(
              "el-button",
              {
                staticClass: "fill-box",
                attrs: { size: "small", type: "primary" },
                on: { click: _vm.downloadTemplate },
              },
              [_vm._v("下载模板")]
            ),
          ],
          1
        ),
        _c("li", { staticClass: "label" }, [_vm._v("第二步")]),
        _c(
          "li",
          [
            _c(
              "el-upload",
              {
                staticClass: "fill-box",
                attrs: {
                  action: "#",
                  accept: ".zip",
                  "show-file-list": true,
                  limit: 1,
                  "auto-upload": false,
                  "on-change": _vm.fileChange,
                },
              },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "fill-box",
                    attrs: { size: "small", type: "primary" },
                  },
                  [_vm._v("选择本地文件")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.handleCancel } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.handleConfirm },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }