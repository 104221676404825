<template>
  <div class="js-common-pagination">
    <el-pagination
      background
      layout="prev, pager, next, jumper"
      :page-size="pages.perPage"
      :current-page.sync="pages.page"
      :total="pages.total"
      @current-change="handleCurrentChange">
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: '',
  props: ['value'],
  data() {
    return {}
  },
  computed: {
    pages: {
      get(){
        return this.value
      },
      set(newVal){
        this.$emit('input', newVal)
      }
    }
  },
  mounted() {
    
  },
  methods: {
    handleCurrentChange(val){
      this.pages.page = val
      this.$emit('curPageChange')
    }
  },
};
</script>

<style lang="scss" scoped>

</style>