var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "js-common-pagination" },
    [
      _c("el-pagination", {
        attrs: {
          background: "",
          layout: "prev, pager, next, jumper",
          "page-size": _vm.pages.perPage,
          "current-page": _vm.pages.page,
          total: _vm.pages.total,
        },
        on: {
          "update:currentPage": function ($event) {
            return _vm.$set(_vm.pages, "page", $event)
          },
          "update:current-page": function ($event) {
            return _vm.$set(_vm.pages, "page", $event)
          },
          "current-change": _vm.handleCurrentChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }