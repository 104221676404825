<template>
  <el-date-picker v-model="dateRange" @change="handleChange" value-format="yyyy-MM-dd" :unlink-panels="true"  size="small" type="date" range-separator="至" start-placeholder="选择开始日期" end-placeholder="选择结束日期"></el-date-picker>
</template>

<script>
export default {
  name: '',
  props: ['value'],
  data() {
    return {
      
    };
  },
  computed: {
    dateRange: {
      get(){
        return this.value
      },
      set(newVal){
        this.$emit('input', newVal)
      }
    }
  },
  mounted() {
    
  },
  methods: {
    handleChange(val){
      console.log('选择的日期是……')
      this.dateRange = val
    },
  },
};
</script>

<style lang="scss" scoped>

</style>