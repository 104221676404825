var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "js-dialog-form js-face-add",
      attrs: {
        title: _vm.isModify ? "修改人像" : "添加人像",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        width: "560px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("ul", [
        _c(
          "li",
          [
            _c("div", { staticClass: "label common-tip" }, [
              _c("i", [_vm._v("*")]),
              _vm._v("人像照片"),
            ]),
            _vm.params.photo
              ? _c("img", {
                  staticClass: "img",
                  attrs: { src: _vm.params.photo, alt: "" },
                })
              : _c(
                  "el-upload",
                  {
                    staticClass: "fill-box",
                    attrs: {
                      action: "#",
                      "show-file-list": false,
                      "auto-upload": false,
                      "on-change": _vm.handleHttpRequest,
                    },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "fill-box",
                        attrs: { size: "small", type: "primary" },
                      },
                      [_vm._v("选择本地文件")]
                    ),
                  ],
                  1
                ),
          ],
          1
        ),
        _c(
          "li",
          [
            _c("div", { staticClass: "label common-tip" }, [
              _c("i", [_vm._v("*")]),
              _vm._v("姓名"),
            ]),
            _c("el-input", {
              attrs: { placeholder: "请输入姓名", size: "small" },
              model: {
                value: _vm.params.name,
                callback: function ($$v) {
                  _vm.$set(_vm.params, "name", $$v)
                },
                expression: "params.name",
              },
            }),
          ],
          1
        ),
        _c(
          "li",
          [
            _c("div", { staticClass: "label common-tip" }, [
              _c("i", [_vm._v("*")]),
              _vm._v("身份"),
            ]),
            _c("el-input", {
              attrs: { placeholder: "请输入身份", size: "small" },
              model: {
                value: _vm.params.idcard,
                callback: function ($$v) {
                  _vm.$set(_vm.params, "idcard", $$v)
                },
                expression: "params.idcard",
              },
            }),
          ],
          1
        ),
        _c(
          "li",
          [
            _c("div", { staticClass: "label common-tip" }, [
              _c("i", [_vm._v("*")]),
              _vm._v("生日"),
            ]),
            _c("date", {
              model: {
                value: _vm.params.birthday,
                callback: function ($$v) {
                  _vm.$set(_vm.params, "birthday", $$v)
                },
                expression: "params.birthday",
              },
            }),
          ],
          1
        ),
        _c(
          "li",
          {},
          [
            _c("div", { staticClass: "label common-tip" }, [
              _c("i", [_vm._v("*")]),
              _vm._v("性别"),
            ]),
            _c(
              "el-select",
              {
                attrs: { placeholder: "请选择性别", size: "small" },
                model: {
                  value: _vm.params.gender,
                  callback: function ($$v) {
                    _vm.$set(_vm.params, "gender", $$v)
                  },
                  expression: "params.gender",
                },
              },
              _vm._l(_vm.options, function (item) {
                return _c("el-option", {
                  key: item.value,
                  attrs: { label: item.label, value: item.value },
                })
              }),
              1
            ),
          ],
          1
        ),
        _c(
          "li",
          {},
          [
            _c("div", { staticClass: "label common-tip" }, [
              _c("i", [_vm._v("*")]),
              _vm._v("民族"),
            ]),
            _c("el-input", {
              attrs: { placeholder: "请输入民族", size: "small" },
              model: {
                value: _vm.params.nation,
                callback: function ($$v) {
                  _vm.$set(_vm.params, "nation", $$v)
                },
                expression: "params.nation",
              },
            }),
          ],
          1
        ),
        _c(
          "li",
          [
            _c("div", { staticClass: "label common-tip" }, [
              _c("i", [_vm._v("*")]),
              _vm._v("地址"),
            ]),
            _c("el-input", {
              attrs: { placeholder: "请输入地址", size: "small" },
              model: {
                value: _vm.params.address,
                callback: function ($$v) {
                  _vm.$set(_vm.params, "address", $$v)
                },
                expression: "params.address",
              },
            }),
          ],
          1
        ),
        _vm.isModify
          ? _c(
              "li",
              [
                _c("div", { staticClass: "label common-tip" }, [
                  _c("i", [_vm._v("*")]),
                  _vm._v("模型名称"),
                ]),
                _c("el-input", {
                  attrs: { placeholder: "请输入模型名称", size: "small" },
                  model: {
                    value: _vm.params.modelId,
                    callback: function ($$v) {
                      _vm.$set(_vm.params, "modelId", $$v)
                    },
                    expression: "params.modelId",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c("li", { staticClass: "label" }),
        _c(
          "li",
          [
            _c(
              "el-radio-group",
              {
                attrs: { size: "small" },
                model: {
                  value: _vm.params.status,
                  callback: function ($$v) {
                    _vm.$set(_vm.params, "status", $$v)
                  },
                  expression: "params.status",
                },
              },
              [
                _c("el-radio", { attrs: { label: 0 } }, [_vm._v("正常")]),
                _c("el-radio", { attrs: { label: 1 } }, [_vm._v("警示")]),
                _c("el-radio", { attrs: { label: 2 } }, [_vm._v("VIP")]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.handleCancel } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.handleConfirm },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }