var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("el-date-picker", {
    attrs: {
      "value-format": "yyyy-MM-dd",
      "unlink-panels": true,
      size: "small",
      type: "date",
      "range-separator": "至",
      "start-placeholder": "选择开始日期",
      "end-placeholder": "选择结束日期",
    },
    on: { change: _vm.handleChange },
    model: {
      value: _vm.dateRange,
      callback: function ($$v) {
        _vm.dateRange = $$v
      },
      expression: "dateRange",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }