<template>
  <el-dialog class="js-dialog-form" title="删除" :visible.sync="dialogVisible" :close-on-click-modal="false" width="676px">
    <ul>
      <li>删除后数据无法恢复！</li>
    </ul>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleCancel" size="small">取 消</el-button>
      <el-button type="primary" @click="handleConfirm" size="small">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: '',
  props: ['value'],
  data() {
    return {
    }
  },
  computed: {
    dialogVisible: {
      get(){
        return this.value
      },
      set(newVal){
        this.$emit('input', newVal)
      }
    }
  },
  mounted() {
  },
  methods: {
    handleCancel(){
      this.dialogVisible = false
    },
    handleConfirm(){
      this.$emit('handleDelete')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>