<template>
  <div class="js-common-wrap js-standard-operation">
    <div class="js-common-head">
      <div>
        <el-button class="back-btn" @click="$goBack" icon="el-icon-arrow-left" size="small" type="primary" plain></el-button>
        <label class="label">人像库</label>
      </div>
      <div>
        <el-button type="primary" size="small" @click="handleImport">导入人像</el-button>
        <el-button type="primary" size="small" @click="add(0,'')">新增人像</el-button>
      </div>
    </div>
    <div class="js-common-content">
      <el-table class="js-table" :data="tableData" height="100%" empty-text="暂无数据" @row-click="handleRowClick">
        <el-table-column prop="id" label="ID"></el-table-column>
        <el-table-column label="图片" width="150">
          <template slot-scope="scope">
            <img class="img" :src="scope.row.photo" alt="">
          </template>
        </el-table-column>
        <el-table-column prop="name" label="姓名"></el-table-column>
        <el-table-column prop="idcard" label="身份" width="170"></el-table-column>
        <el-table-column>
          <template slot="header" slot-scope="scope">
            <label class="table-is-warning">
              是否警示<el-checkbox class="warning" v-model="isWarning" @change="changeWarning"></el-checkbox>
            </label>
          </template>
          <template slot-scope="scope">
            <span v-if="scope.row.status == 0">正常</span>
            <span v-if="scope.row.status == 1">警示</span>
            <span v-if="scope.row.status == 2">VIP</span>
          </template>
        </el-table-column>
        <el-table-column prop="gmtCreated" label="创建时间" width="160"></el-table-column>
        <el-table-column prop="gmtUpdated" label="上次修改时间" width="160"></el-table-column>
        <el-table-column prop="modelId" label="模型名称ID"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <i class="el-icon-edit-outline" @click.stop="add(1,scope.row)"></i>
            <i class="el-icon-delete" @click.stop="deleteItem(scope.row)"></i>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <common-pagination v-model="pageOptions" @curPageChange="handleCurPageChange"></common-pagination>
    <face-import v-model="faceImportDialogVisible" :id="currentID" @uploadSuccess="uploadSuccess"></face-import>
    <face-add v-model="addDialogVisible"  :currentItem="currentItem" :isModify="isModify" @handleAdd="handleAdd"></face-add>
    <common-delete v-model="deleteDialogVisible" @handleDelete="handleDelete"></common-delete>
  </div>
</template>
<script>
import CommonPagination from '@/components/CommonPagination'
import FaceImport from '@/components/FaceImport'
import FaceAdd from '@/components/FaceAdd'
import CommonDelete from '@/components/CommonDelete'
export default {
  name: '',
  components: {
    CommonPagination,
    FaceImport,
    FaceAdd,
    CommonDelete
  },
  data() {
    return {
      tableData: [],
      faceImportDialogVisible: false,
      addDialogVisible: false,
      deleteDialogVisible: false,
      pageOptions:{
        page: 1,
        perPage: 20,
        total: 0
      },
      isModify: '',
      currentID: '',
      currentItem: '',
      isWarning: 0
    }
  },
  mounted() {
    this.currentID = this.$route.query.id
    this.getList()
  },
  methods: {
    getList(){
      this.$axios({
        method: 'get',
        url: `api/csp/afr/v1/afr/lib/${this.currentID}`,
        params:{
          afrLibId: this.currentID,
          page: this.pageOptions.page,
          perPage: this.pageOptions.perPage
        }
      }).then((response)=>{
        let data = response.data
        if(!data.status){
          this.tableData = data.data.rows
          this.pageOptions.total = data.data.total
        }else{
          this.$message({
            type: 'error',
            message: data.msg,
            duration: 1000
          })
        }
      })
    },
    // 分页
    handleCurPageChange(){
      this.getList()
    },
    add(flag,item){
      this.isModify = flag
      this.addDialogVisible = true
      if(item){
        this.currentItem = this.$Utils.deepCopy(item)
      }else{
        this.currentItem = {
          photo:'',
          name: '',
          idcard: '',
          birthday: '',
          gender: '',
          nation: '',
          address: '',
          status: '',
          modelId: '',
          afrLibId: this.currentID
        }
      }
    },
    handleAdd(params){
      let method = ''
      let url = ''
      if(this.isModify){
        method = 'put'
        url = `api/csp/afr/v1/afr/${this.currentItem.id}`
      }else{
        method = 'post'
        url = 'api/csp/afr/v1/afr/'
      }
      this.$axios({
        method: method,
        url: url,
        data: {
          data: {
            photo: params.photo,
            name: params.name,
            idcard: params.idcard,
            birthday: params.birthday,
            gender: params.gender,
            nation: params.nation,
            address: params.address,
            status: params. status,
            modelId: '',
            afrLibId: this.currentID
          }
        }
      }).then((response)=>{
        let data = response.data
        if(!data.status){
          this.addDialogVisible = false
          this.$message({
            type: 'success',
            message: '新增成功！',
            duration: 1000
          })
          this.pageOptions.page = 1
          this.getList()
        }else{
          this.$message({
            type: 'error',
            message: data.msg,
            duration: 1000
          })
        }
      })
    },
    deleteItem(item){
      this.currentItem = item
      this.deleteDialogVisible = true
    },
    handleDelete(){
      this.$axios({
        method: 'delete',
        url: `api/csp/afr/v1/afr/${this.currentItem.id}`
      }).then((response)=>{
        let data = response.data
        if(!data.status){
          this.deleteDialogVisible = false
          this.$message({
            type: 'success',
            message: '删除成功！',
            duration: 1000
          })
          this.pageOptions.page = 1
          this.getList()
        }else{
          this.$message({
            type: 'error',
            message: data.msg,
            duration: 1000
          })
        }
      })
    },
    handleImport(){
      console.log('导入…………………………')
      this.faceImportDialogVisible = true
    },
    handleRowClick(row){
      console.log(row)
      this.$router.push({
        path: `/aiRecognition/faceData/faceDetails`,
        query:{
          id: row.id
        }
      })
    },
    uploadSuccess(){
      this.faceImportDialogVisible = false
      this.getList()
    },
    changeWarning(){
      this.$axios({
        method: 'post',
        url: 'api/csp/afr/v1/afr/warning',
        data:{
          data:{
            afrLibId: this.currentID,
            status: this.isWarning ? 1:0
          }
        }
      }).then((response)=>{
        let data = response.data
        if(!data.status){
          this.$message({
            type: 'success',
            message: '状态修改成功！',
            duration: 1000
          })
          this.pageOptions.page = 1
          this.getList()
        }else{
          this.$message({
            type: 'error',
            message: data.msg,
            duration: 1000
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
