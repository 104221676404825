<template>
  <el-dialog class="js-dialog-form js-face-add" :title="isModify?'修改人像':'添加人像'" :visible.sync="dialogVisible" :close-on-click-modal="false" width="560px">
    <ul>
      <!-- <li class="label common-tip"><i>*</i>人像照片</li> -->
      <li>
        <div class="label common-tip"><i>*</i>人像照片</div>
        <img v-if="params.photo" class="img" :src="params.photo" alt="">
        <el-upload 
          v-else class="fill-box" 
          action="#" 
          :show-file-list="false"
          :auto-upload="false" 
          :on-change="handleHttpRequest">
          <el-button class="fill-box" size="small" type="primary">选择本地文件</el-button>
        </el-upload>
      </li>
      <!-- <li class="label common-tip"><i>*</i>姓名</li> -->
      <li>
        <div class="label common-tip"><i>*</i>姓名</div>
        <el-input v-model="params.name" placeholder="请输入姓名" size="small"></el-input>
      </li>
      <!-- <li class="label common-tip"><i>*</i>身份</li> -->
      <li>
        <div class="label common-tip"><i>*</i>身份</div>
        <el-input v-model="params.idcard" placeholder="请输入身份" size="small"></el-input>
      </li>
      <!-- <li class="label">生日</li> -->
      <li>
        <div class="label common-tip"><i>*</i>生日</div>
        <date v-model="params.birthday"></date>
      </li>
      <!-- <li class="label half-box left">性别</li> -->
      <!-- <li class="label half-box right">民族</li> -->
      <li class="">
        <div class="label common-tip"><i>*</i>性别</div>
        <el-select v-model="params.gender" placeholder="请选择性别" size="small">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </li>
      <li class="">
        <div class="label common-tip"><i>*</i>民族</div>
        <el-input v-model="params.nation" placeholder="请输入民族" size="small"></el-input>
      </li>
      <!-- <li class="label">地址</li> -->
      <li>
        <div class="label common-tip"><i>*</i>地址</div>
        <el-input v-model="params.address" placeholder="请输入地址" size="small"></el-input>
      </li>
      <!-- <li v-if="isModify" class="label">模型名称</li> -->
      <li v-if="isModify">
        <div class="label common-tip"><i>*</i>模型名称</div>
        <el-input v-model="params.modelId" placeholder="请输入模型名称" size="small"></el-input>
      </li>
      <li class="label"></li>
      <li>
        <el-radio-group v-model="params.status" size="small">
          <el-radio :label="0">正常</el-radio>
          <el-radio :label="1">警示</el-radio>
          <el-radio :label="2">VIP</el-radio>
        </el-radio-group>
      </li>
    </ul>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleCancel" size="small">取 消</el-button>
      <el-button type="primary" @click="handleConfirm" size="small">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import Date from '@/components/Date'
export default {
  name: '',
  props: ['value','isModify','currentItem'],
  components: {
    Date
  },
  data() {
    return {
      action: '',
      imgUrl: '',
      options: [{
        label: '男',
        value: 1
      },{
        label: '女',
        value: 2
      },{
        label: '未知',
        value: 0
      }]
    }
  },
  computed: {
    dialogVisible: {
      get(){
        return this.value
      },
      set(newVal){
        this.$emit('input', newVal)
      }
    },
    params(){
      return this.currentItem
    }
  },
  mounted() {
  },
  methods: {
    // 获取上传文件地址
    getUploadDir(){
      return new Promise((resolve,reject)=>{
        this.$axios({
          method: 'get',
          url: 'api/csp/cos/v1/url/upload',
          params: {
            dirType: 'upload',
            suffix: 'jpeg'
          }
        }).then((response)=>{
          let data = response.data
          if(!data.status){
            this.action = data.data
          }else{
            this.$message({
              type: 'error',
              message: data.msg,
              duration: 1000
            })
          }
          resolve()
        }).catch(()=>{
          reject()
        })
      })
    },
    async handleHttpRequest(file){
      await this.getUploadDir()
      var xhr = new XMLHttpRequest()
      xhr.open('PUT', this.action.url, true)
      xhr.onload = (e) => {
        // console.log('上传成功', xhr.status, xhr.statusText)
        // console.log(this.action)
        this.params.photo = this.action.url.split('?')[0]
      }
      xhr.onerror = (e) => {
        // console.log('上传出错', xhr.status, xhr.statusText)
      }
      xhr.send(file.raw)
    },
    handleCancel(){
      this.dialogVisible = false
    },
    handleConfirm(){
      let tmp = {
        photo: this.action.file,
        name: this.params.name,
        idcard: this.params.idcard,
        birthday: this.params.birthday,
        gender: this.params.gender,
        nation: this.params.nation,
        address: this.params.address,
        status: this.params. status,
        modelId: '',
      }
      this.$emit('handleAdd',tmp)
    }
  }
}
</script>

<style lang="scss" scoped>
.js-face-add{
  .half-box{
    width: 50%;
    display: inline-block;
  }
  .img{
    width: 120px;
    height: 120px;
  }
}
</style>

<style lang="scss">
.js-face-add{
  .fill-box{
    width: 100%;
    height: 55px;
    font-size: 18px;
    border-radius: 8px;
    .el-upload{
      width: 100%;
    }
  }
}
.js-face-add{
  .half-box{
    .el-select{
      width: 100%;
    }
    &.left{
      padding-right: 5px;
    }
    &.right{
      padding-left: 5px;
    }
  }
}
</style>